@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/box.sass";
@import "~bulma/sass/elements/form.sass";
@import "~bulma/sass/elements/table.sass";
@import "~bulma/sass/layout/section.sass";

body {
  background-color: #FFB40F;
  text-align: center;
  background-size: auto 100%;
  min-height: 100vh;
  text-align: center;
  display: flex;           /* establish flex container */
  flex-direction: column;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;
}

.input {
  width: 50vw;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.inferno-logo {
  background: #fff;
  width: 6rem;
  height: 6rem;
  padding: 2rem;
  border-radius: 2rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


